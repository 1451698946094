export const semanticTokens = {
  colors: {
    'body': {
      _dark :  'oklab(0.296332 -0.000731647 -0.00768477)',
      _light: 'white',
    },
    'chakra-body-text': { _light: 'gray.800', _dark: 'oklab(0.899401 -0.00192499 -0.00481987)' },
    'editorLink': {
      _dark :  'blue.500',
      _light: 'blue.500',
    },
  },
};