const brand = {
  50: '#f9f9f9',
  200: '#eeeeee',
  300: '#b4b4b4',
  400: '#373A40 ',
  500: '#222222',
  600: '#111111',
  700: '#222222',
  800: '#222222',
  900: '#222222',
};

export const colors = {
  brand,
};