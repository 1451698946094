import { Config } from 'shared/types/config';

const config: Config = {
  devtools: {
    query: false,
    router: false,
    tabRouter: false,
  },
};

export default config;
